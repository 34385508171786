.offer-page {
    .horizontal-inner-container {
        margin-top: 20px;

        .left-column {
            color: $brand-dark-color-1;

            .offer-description-box {
                background-color: $brand-color-3;
                padding: 35px 30px;
                margin-bottom: 20px;
                position: relative;
                z-index: 10px;

                h3 {
                    @include font(30,34,400);
                    font-family: $brand-font-1;
                    margin: 30px 0;
                    text-transform: uppercase;
                }

                h4 {
                    @include font(25,27,400);
                    font-family: $brand-font-1;
                    margin: 0;
                }

                p {
                    @include font(16,18,100);
                    font-family: $brand-font-1;
                }
            }

            .offer-profile-box {
                background-color: $brand-color-4;
                padding: 35px 30px;
                margin-bottom: 20px;

                h4 {
                    @include font(25,27,400);
                    font-family: $brand-font-1;
                    margin: 0;
                }

                p {
                    @include font(16,18,100);
                    font-family: $brand-font-1;
                }
            }

            .buttons-sections {
                text-align: center;
                margin-bottom: 20px;

                .btn {
                    width: 280px;
                }

                .btn-big {
                    @include font(30,34,600);
                    font-family: $brand-font-2;
                    padding: 15px 12px;
                }

                .btn-secondary {
                    padding: 7px 12px;
                }
            }

            .social-icons-list {
                width: 33px;
                position: relative;
                left: -26px;
                top: 50px;
                height: 242px;
                margin-bottom: -242px;

                .social-icon {
                    margin-bottom: 15px;
                    position: relative;

                    &:focus,
                    &:hover {
                        left: -6px;
                    }
                }
            }
        }

        .right-column {
            .btn {
                width: 100%;
                @include font(30,34,600);
                font-family: $brand-font-2;
                padding: 15px 12px;
                margin-bottom: 10px;
            }

            h4 {
                @include font(25,27,400);
                font-family: $brand-font-1;
                margin: 0 0 20px;
            }

            .details-box {
                margin-top: 10px;
                margin-bottom: 20px;
                padding: 25px;
                background-color: $brand-color-1;
                color: #fff;

                .detail-description {
                    background-repeat: no-repeat;
                    background-position: 20px 0;
                    padding-left: 60px;
                    min-height: 30px;

                    p {
                        @include font(18,20,100);
                        font-family: $brand-font-1;
                        text-align: left;
                    }
                }

                p {
                    @include font(12,20,100);
                    font-family: $brand-font-1;
                    text-align: center;
                }
            }

            .location-box {
                margin-top: 10px;
                margin-bottom: 20px;
                padding: 25px;
                background-color: $brand-color-3;
                color: $brand-dark-color-1;

                h4 {
                    @include font(25,27,400);
                    font-family: $brand-font-1;
                    margin: 0 0 20px;
                }

                #map-container {
                    height: 300px;
                    width: 100%;
                }
            }
        }
    }
}

.offers-separator {
    border-bottom: 1px solid $brand-color-3;
}

.related-offers {
    padding: 40px 0;

    @include mq(tablet) {
        padding: 40px;
    }

    .horizontal-inner-container {
        overflow: hidden;

        h2 {
            @include font(30,34,600);
            font-family: $brand-font-2;
            color: $brand-color-1;
            text-align: center;
            margin-top: 0;
            margin-bottom: 20px;
        }

        .related-box {
            margin-top: 20px;
            cursor: pointer;

            .related-inner-box {
                padding: 20px 20px 25px 0;
                background-color: $brand-color-5;
                background-repeat: no-repeat;
                background-size: cover;

                .title-box {
                    width: 100%;
                    min-height: 80px;
                    padding: 20px;
                    background-color: $brand-color-1;
                    color: #fff;
                    @include font(20,26,600);
                    font-family: $brand-font-1;
                    text-transform: uppercase;
                    margin-bottom: 10px;
                }

                .tags-line {
                    overflow: hidden;

                    .tag-box {
                        float: left;
                        background-color: $brand-light-color-3;
                        margin-right: 15px;
                        padding: 10px 10px 10px 35px;
                        @include font(17,20,100);
                        text-transform: uppercase;
                        font-family: $brand-font-1;
                        color: $brand-color-1;
                        background-repeat: no-repeat;
                        background-position: 9px 8px;
                    }
                }

                .offer-options {
                    text-align: center;

                    .date-offer {
                        font-family: $brand-font-1;
                        @include font(16,18,100);
                        font-style: italic;
                        float: left;
                        padding-left: 15px;
                        color: $brand-light-color-8;
                        border-bottom: 1px solid $brand-light-color-8;
                        margin-top: 20px;
                    }

                    .btn {
                        margin-top: 15px;
                    }
                }
            }

            .related-inner-box-hover {
                height: 10px;
                background-color: $brand-color-2;
                display: none;
            }

            &.active {
                .related-inner-box {
                    .title-box {
                        background-color: $brand-color-2;
                    }
                }

                .related-inner-box-hover {
                    display: block;
                }
            }
        }
        @include mq(tablet) {
            .related-box-0 {
                padding: 0 27px 0 0;
            }

            .related-box-1 {
                padding: 0 27px;
            }

            .related-box-2 {
                padding-left: 0 0 0 27px;
            }
        }
    }
}

.loader {
    height: 40px;
    width: 40px;
    display: none;
}

.offer-page .horizontal-inner-container .right-column .unactivated {
    opacity: 0.6;
    @include font(15,34,600)
}

.offer-page .horizontal-inner-container .left-column .buttons-sections .unactivated {
    opacity: 0.6;
    @include font(15,34,600);
    width: 350px;
}
