.home {
    $homeHeight: 610px;

    .home-block-1 {
        @include mq(tablet) {
            height: $homeHeight;
        }

        background-repeat: no-repeat;
        background-color: $brand-light-color-1;
        background-position: center;

        .horizontal-inner-container {
            height: $homeHeight;
        }

        .background-image {
            z-index: 5;
            background-size: 250%;
            background-position: top left;

            @include mq(tablet) {
                background-size: auto;
                background-position: top center;
            }
        }

        .background-decoration {
            height: 9px;
            background-color: $brand-color-1;
            position: absolute;
            width: 40%;
            left: 0;
            top: 104px;
            z-index: 1;
            display: none;

            @include mq(tablet) {
                display: block;
            }
        }

        p.quote {
            z-index: 10;
            color: $brand-dark-color-1;
            @include font(32,36,300);
            font-family: $brand-font-2;
            position: absolute;
            top: 260px;
            left: 5%;
            font-style: italic;
            width: 90%;
            text-align: center;

            @include mq(tablet) {
                top: 381px;
                width: 600px;
                left: 30px;
            }

            &:after {
                content: '“';
                position: absolute;
                top: -20px;
                left: -36px;
                color: $brand-color-1;
                font-family: $brand-font-2;
                font-size: 90px;
                line-height: 90px;
            }

            &:before {
                content: '”';
                position: absolute;
                bottom: -70px;
                right: -20px;
                color: $brand-color-1;
                font-family: $brand-font-2;
                font-size: 90px;
                line-height: 90px;
            }
        }
    }

    h2 {
        font-family: $brand-font-2;
        @include font(40,44,400);
        font-style: italic;
        padding-bottom: 20px;

        .small {
            @include font(30,34,300);
        }
    }

    p {
        @include font(18,22,100);
        padding-bottom: 15px;
    }

    .home-block-2 {
        height: $homeHeight;

        .background-image-1 {
            background-size: auto;
            left: 0;
            display: none;
            @include mq(tablet) {
                display: block;
                left: -170px;
            }
        }

        .background-image-2 {
            background-size: auto;
            right: 0;
            bottom: 0;
            background-position: right bottom;
        }

        .home-block-content {
            padding-top: 0;
            padding-left: 0;
            @include mq(tablet) {
                padding-top: 140px;
                padding-left: 410px;
            }
            color: $brand-color-1;

            span {
                color: $brand-color-1;
            }

            ul {
                list-style-type: none;

                li {}
            }

            a.btn {
                margin-top: 25px;
            }
        }
    }

    .home-block-3 {
        background-color: #eef1f3;
        padding: 40px 20px 35px;
        color: $brand-dark-color-1;

        h2 {
            text-align: center;
        }

        .item {
            .image {
                display: none;
                @include mq(tablet) {
                    position: absolute;
                    top: -70px;
                    left: 16px;
                }
            }

            .content {

                padding-bottom: 40px;

                @include mq(tablet) {
                    min-height: 330px;
                    min-height: 330px;
                    padding-left: 370px;
                    padding-right: 140px;
                }

                .btn {
                    display: inline-block;
                    margin: 0 auto;

                    @include font(12,18,600);

                    @include mq(tablet) {
                        @include font(18,22,600);
                    }
                }

                h3 {
                    font-family: $brand-font-2;
                    @include font(22,22,400);

                    @include mq(tablet) {
                        @include font(40,44,400);
                    }

                    font-style: italic;
                    padding-bottom: 0;
                    margin-bottom: 5px;
                }

                p {
                    @include font(20,23,400);
                    padding-bottom: 10px;
                }

                p.date {
                    background-color: $brand-color-1;
                    padding: 3px 7px;
                    @include font(14,16,400);
                    display: inline-block;
                    color: #fff;
                }

                p.categorie {
                    background-color: $brand-light-color-3;
                    padding: 3px 7px;
                    @include font(14,16,400);
                    display: inline-block;
                }
            }

            &.right {
                .image {
                    right: 0;
                    left: auto;
                }

                .content {
                    padding-right: 370px;
                    padding-left: 140px;
                }
            }
        }

        .more-info {
            text-align: center;
        }
    }

    .home-block-4 {

        padding: 45px 0 45px 0;

        @include mq(tablet) {
            padding: 45px 20px 70px;
        }

        .background-image-2 {
            background-size: auto;
            background-position: left bottom;
            z-index: 2;
        }

        .background-image-1 {
            background-size: auto;
            background-position: center;
            z-index: 2;
        }

        .background-color-right {
            background-color: $brand-light-color-2;
            margin: 0 0 0 50%;
            z-index: 1;
            width: 50%;
        }

        .horizontal-inner-container {
            z-index: 5;
        }

        .col-md-6:first-child {
            text-align: center;

            h2 {
                color: $brand-color-1;
                text-align: center;
            }
        }

        .content-right {
            padding-left: 0;
            text-align: center;
            @include mq(tablet) {
                padding-left: 60px;
            }

            h2 {
                text-align: center;
            }

            p {
                text-align: left;
                padding-left: 0;

                @include mq(tablet) {
                    padding-left: 50px;
                }
            }
        }
    }
}
