.home-candidate {
    .bk-candidate-menu {
        background-color: #eff2f5;
        background-repeat: no-repeat;
        background-position: right bottom;

        .horizontal-inner-container {
            &.horizontal-inner-container-candidate-hex {
                padding: 0;
                @include mq(800) {
                    padding: 0 30px;
                }
            }
            height: 900px;
            @include mq(1060) {
                height: 760px;
            }
            overflow: hidden;

            .hexagon-candidate {
                position: relative;
                width: 326px;
                height: 188.22px;
                background-color: #637d96;
                margin: 94.11px 0;
                float: left;

                &:after,
                &:before {
                    content: "";
                    position: absolute;
                    width: 0;
                    border-left: 163px solid transparent;
                    border-right: 163px solid transparent;
                }

                &:before {
                    bottom: 100%;
                    border-bottom: 94.11px solid #637d96;
                }

                &:after {
                    top: 100%;
                    width: 0;
                    border-top: 94.11px solid #637d96;
                }

                &.gray-middle {
                    background-color: #8297ab;

                    &:before {
                        border-bottom: 94.11px solid #8297ab;
                    }

                    &:after {
                        border-top: 94.11px solid #8297ab;
                    }
                }

                &.gray-soft {
                    background-color: #b1becb;
                    margin-top: 0;

                    &:before {
                        border-bottom: 94.11px solid #b1becb;
                    }

                    &:after {
                        border-top: 94.11px solid #b1becb;
                    }
                }
            }

            .hexagon-applications {
                position: relative;
                background-color: #637d96;
                float: left;
                width: 486px;
                height: 280.59px;
                margin: 0 0 0 -66px;
                @include mq(1060) {
                    float: right;
                    margin: 140.30px 0;
                }

                &:after,
                &:before {
                    content: "";
                    position: absolute;
                    width: 0;
                    border-left: 243px solid transparent;
                    border-right: 243px solid transparent;
                }

                &:before {
                    bottom: 100%;
                    border-bottom: 140.30px solid #637d96;
                }

                &:after {
                    top: 100%;
                    width: 0;
                    border-top: 140.30px solid #637d96;
                }
            }

            .candidate-profile-div {
                float: left;
                @include mq(1060) {
                    margin-left: 2px;
                    padding-top: 139px;
                }
            }

            .candidate-alert-div {
                float: left;
                clear: both;
                margin: -96px 0 0 256px;
                @include mq(1060) {
                    float: right;
                    padding-top: 139px;
                    clear: none;
                    margin: 0;
                }
            }

            .second-row {
                overflow: hidden;
                padding-top: 95px;
                float: left;
                margin: -95px 0 0 93px;
                width: 652px;
                @include mq(1060) {
                    margin: -143px 0 0 165px;
                }

                .candidate-documents-div {
                    float: right;
                    clear: both;
                    margin: 0;
                    @include mq(1060) {
                        float: left;
                        margin: 0;
                    }
                }

                .contact-contact-div {
                    float: left;
                    clear: both;
                    margin: -377px 0 0;
                    @include mq(1060) {
                        float: right;
                        margin: -376px 0 0;
                    }
                }
            }

            .hex-content {
                text-align: center;
                color: #fff;

                .candidate-icon {
                    height: 68px;
                    width: 100%;
                    background-repeat: no-repeat;
                    background-position: center bottom;
                }

                h3 {
                    margin-bottom: 0;
                }

                p {
                    margin-bottom: 0;
                }

                a {
                    margin-top: 10px;
                }

                &.profile-content {
                    .candidate-icon {
                        height: 75px;
                        margin-top: -37px;
                    }

                    h3 {
                        margin-top: 12px;
                    }
                }
            }

            .hexagon-applications {
                .hex-content {
                    .candidate-icon {
                        height: 131px;
                    }
                }
            }
        }
    }
}
