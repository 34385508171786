.btn {
  background-color:$brand-color-2;
  text-transform:uppercase;
  padding:5px 12px;
  @include font(18,22,700);
  color:#fff;
  min-width:225px;
  border:none;
  border-radius: 0px;
  font-family:$brand-font-2;

  @extend .animate;

  &:hover, &:focus, &:active, &:active:focus{
    background-color:$brand-light-color-5;
    color:#fff;
    outline: none;
  }

  &:visited{
    background-color:$brand-color-2;
  }

  &.btn-secondary{
    background-color:$brand-color-1;

    &:hover, &:focus, &:active, &:active:focus {
      background-color:$brand-dark-color-1;
    }

    &:visited{
      background-color:$brand-color-1;
    }
  }
}
