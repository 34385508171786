// Fonts
@import url('https://fonts.googleapis.com/css?family=Ubuntu:300,500');
@import url('https://fonts.googleapis.com/css?family=Merriweather:300i,400,400i,700,700i');

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

@import '../helpers/common';
@import '../helpers/mixins';
@import '../helpers/breakpoints';

//layouts
@import 'layout';
@import 'buttons';
@import 'form';

@import 'home';
@import 'results';
@import 'offer';
@import 'blog-index';
@import 'blog-post';
@import 'modals';
@import 'reset-password';
@import 'candidate-home';
@import 'candidate-profile';
@import 'candidate-spontanious';
