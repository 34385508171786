.categories-bar{
	background-color: $brand-color-5;
	padding: 20px 0;
	h4{
		@include font(25,27,400);
		font-family: $brand-font-1;
		color:$brand-dark-color-1;
		float:left;
		margin-right: 10px;
	}
	.label-box{
		background-color:$brand-light-color-3;
		padding: 3px 7px;
		@include font(14,16,400);
		display:inline-block;
		margin: 12px 5px 0px 5px;
		color:$brand-dark-color-1;

	}
}
.blog-results{
	padding-top: 70px;
    .item {
		.image {
		position:absolute;
		top: -40px;
		left: 16px;
		}

		.content {
			padding-left:240px;
			min-height:290px;
			padding-right:100px;
			 @include mq(tablet){			 	
				padding-left:370px;
				min-height:350px;
				padding-right:140px;
			 }
			h3{
			  font-family: $brand-font-2;
			  @include font(40,44,400);
			  font-style: italic;
			  padding-bottom: 0px;
			  margin-bottom: 5px;
			}

			p {
			  @include font(20,23,400);
			  padding-bottom:10px;
			}

			p.date{
			  background-color:$brand-color-1;
			  padding: 3px 7px;
			  @include font(14,16,400);
			  display:inline-block;
			  color:#fff;
			}

			p.categorie {
			  	background-color:$brand-light-color-3;
			  	padding: 3px 7px;
			  	@include font(14,16,400);
			  	display:inline-block;
	  			color:$brand-dark-color-1;
			}

		}

		&.right{
			.image {
			  right:0px;
			  left:auto;
			}

			.content {
			  padding-right:240px;
			  padding-left:100px;
			  @include mq(tablet){			 	
				padding-right:370px;
			  	padding-left:140px;
			 }
			}

		}

    }
}


.hexagon {
	position: relative;
	width: 200px; 
	height: 115.47px;
	margin: 57.74px 0;
	background-size: auto 207.8461px;
	background-position: center;
	border-left: solid 10px #333333;
	border-right: solid 10px #333333;

	.hexTop,
	.hexBottom {
		position: absolute;
		z-index: 1;
		width: 141.42px;
		height: 141.42px;
		overflow: hidden;
		-webkit-transform: scaleY(0.5774) rotate(-45deg);
		-ms-transform: scaleY(0.5774) rotate(-45deg);
		transform: scaleY(0.5774) rotate(-45deg);
		background: inherit;
		left: 19.29px;
	}

	.hexTop:after,
	.hexBottom:after {
		content: "";
		position: absolute;
		width: 180.0000px;
		height: 103.92304845413265px;
		-webkit-transform:  rotate(45deg) scaleY(1.7321) translateY(-54.8483px);
		-ms-transform:      rotate(45deg) scaleY(1.7321) translateY(-54.8483px);
		transform:          rotate(45deg) scaleY(1.7321) translateY(-54.8483px);
		-webkit-transform-origin: 0 0;
		-ms-transform-origin: 0 0;
		transform-origin: 0 0;
		background: inherit;
	}
	.hexTop {
		top: -70.7107px;
		border-top: solid 14.1421px #333333;
		border-right: solid 14.1421px #333333;
	}

	.hexTop:after {
		background-position: center top;
	}

	.hexBottom {
		bottom: -70.7107px;
		border-bottom: solid 14.1421px #333333;
		border-left: solid 14.1421px #333333;
	}

	.hexBottom:after {
		background-position: center bottom;
	}


	@include mq(tablet){
		width: 311px; 
		height: 179.56px;
		margin: 89.78px 0;
		background-size: auto 336.0179px;

	  	.hexTop,
		.hexBottom {
		  width: 219.91px;
		  height: 219.91px;
		  left: 35.54px;
		}

		/*counter transform the bg image on the caps*/
		.hexTop:after,
		.hexBottom:after {
		  width: 291.0000px;
		  height: 168.00892833418112px;
		  -webkit-transform:  rotate(45deg) scaleY(1.7321) translateY(-84.0045px);
		  -ms-transform:      rotate(45deg) scaleY(1.7321) translateY(-84.0045px);
		  transform:          rotate(45deg) scaleY(1.7321) translateY(-84.0045px);
		}

		.hexTop {
		  top: -109.9551px;
		}

		.hexBottom {
		  bottom: -109.9551px;
		}
	}
}

.hexagon:after {
  content: "";
  position: absolute;
  top: 5.7735px;
  left: 0;
  width: 180.0000px;
  height: 103.9230px;
  z-index: 2;
  background: inherit;

  @include mq(tablet){
	  top: 2.8868px;
	  width: 291.0000px;
	  height: 168.0089px;
  }
}
