.container{
  .reset-password{

      margin-top: 40px;
      margin-bottom:40px;

    .panel-body{
      padding-top:30px;
      background-color:#eff2f5;
    } 
  }
  
}