
//MAIN VARIABLES

// Body
$body-bg: #fff;

// Layout colors
$brand-light-color-1: #e6eaee;  //main gray, home block 1
$brand-light-color-2: #c8d1da;  //main gray darker
$brand-light-color-3: #cdd5dd;  //main gray buttons
$brand-light-color-4: #aab2c5;  //main gray footer icons
$brand-light-color-5 : #af3e69; //pink hover buttons
$brand-light-color-6 : #eff2f5; //gray background results
$brand-light-color-7 : #cd1b6a;  //pink num results text
$brand-light-color-8 : #727fa0;  //gray date results text

$brand-dark-color-1 : #394050; //black blue home-header
$brand-dark-color-2 : #51667a; //black blue home-footer

$brand-color-1 : #637d96; //gray blue header, buttons
$brand-color-2 : #ce1c6a; //dark red, buttons
$brand-color-3 : #e0e5ea; //gray background offer
$brand-color-4 : #c1cbd5; //gray background profil
$brand-color-5 : #f0f0f2; //gray soft related offers
$brand-color-6 : #620719;  //dark red search responsive


$brand-font-1 : 'Ubuntu', sans-serif;
$brand-font-2 : 'Merriweather', serif;


