.horizontal-container {
	max-width: 100%;
	margin: 0 auto;
}

.horizontal-inner-container {
	max-width: 1200px;
	margin: 0 auto;
  padding: 0 30px;
}

a {
	text-decoration: none;
	display:inline-block;
}

b{
  font-weight:bold;
}

.separator {
    clear: both;
    font-size: 1px;
    line-height: 0;
    height: 1px;
    pointer-events: none;
}
.rounded {
    -moz-border-radius: 200px;
    -webkit-border-radius: 200px;
    border-radius: 200px;
}

.uppercase {
    text-transform: uppercase;
}
.animate {
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.box-sizing{
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */
}

div, header,footer,aside,section,article,h1,h2,h3,p,form,a,ul,li {
  position:relative;
  vertical-align: top;

  @extend .box-sizing;

}


.badge-notify{
   background:red;
   position:relative;
   top: -20px;
   left: -35px;
   padding: 3px 5px;
   font-size:10px;
   right: 8px;
   top: -11px;
   left:auto;
}

.bg-white{
  background-color:#fff;
}

.background-image-container{
  position:absolute;
  width:100%;
  height:100%;
  z-index:0;
  top:0px;
  left:0px;

}

.background-image{
  position:absolute;
  width:100%;
  height:100%;
  top:0px;
  left:0px;
  z-index:0;
  background-repeat:no-repeat;
  background-size:cover;
}
