.search-results{
    h2{
      @include font(50,55,600);
      font-family: $brand-font-2;
      font-style: italic;
      color: $brand-light-color-7;
      span{
        @include font(30,34,600);
        font-family: $brand-font-2;
        font-style: italic;
        color: $brand-color-1;
      }
      margin-bottom: 25px;
    }

  .filter-box{
    background-color: $brand-light-color-6;
    padding: 15px 30px;

    h3{
      @include font(30,34,400);
      font-family: $brand-font-2;
      font-style: italic;
      color: $brand-color-1;
    }

    h4{
      @include font(20,23,400);
      font-family: $brand-font-1;
      font-style: medium;
      color: $brand-color-1;
    }
    .btn{
      margin-bottom:10px;
    }

  }
  .offer-box{
      background-repeat: no-repeat;
      background-color: $brand-light-color-6;
      background-size: auto;
      min-height: 170px;
      padding:20px 0px;
      margin-bottom: 20px;
      .title-box{
        width: auto;
        min-height: 80px;
        padding: 20px;
        background-color: $brand-color-1;
        color:#fff;
        @include font(20,26,600);
        font-family: $brand-font-1;
        text-transform: uppercase;
        margin-bottom:10px;

        @include mq(tablet) {
            width: 325px;
        }
      }

      .tag-box{
        float: left;
        background-color:$brand-light-color-3;
        margin-right: 15px;
        padding:10px 10px 10px 35px;
        @include font(17,20,100);
        text-transform: uppercase;
        font-family: $brand-font-1;
        color:$brand-color-1;
        background-repeat: no-repeat;
        background-position: 9px 8px;
      }
      .date-offer{
        font-family: $brand-font-1;
        @include font(16,18,100);
        font-style: italic;
        float:right;
        padding-right: 15px;
        color: $brand-light-color-8;
        border-bottom: 1px solid $brand-light-color-8;
        margin-top:20px;
      }
      .btn{
        float:right;
        margin-right: 20px;
        margin-top: 50px;
      }
  }

}
