.spontanious {
    .bk-candidate-menu {
        background-color: #eff2f5;
        background-repeat: no-repeat;
        background-position: right bottom;
        overflow: hidden;

        .horizontal-inner-container {
            padding-bottom: 30px;

            .candidate-page-content {
                overflow: hidden;
                min-height: 50px;
                padding: 40px 10px 0;

                form {
                    .download {
                        margin: 18px 0 0 10px;
                    }

                    label {
                        margin-top: 20px;
                        @include font (13,15,100);
                        font-family: $brand-font-1;
                        color: $brand-color-1;
                    }

                    .radio-div {
                        text-align: center;

                        input {
                            margin-right: 30px;
                        }
                    }

                    .form-group {
                        margin-bottom: 10px;
                    }

                    .btn-div {
                        margin: 20px 0;
                        text-align: center;
                    }
                }
            }

            &.horizontal-inner-container-candidate-profile {
                .candidate-page-content {
                    h2 {
                        font-family: $brand-font-1;
                        @include font(38,40,100);
                        color: $brand-color-1;
                        padding-left: 15px;
                        text-align: center;
                    }
                }
            }
        }
    }
}
