.candidate {
    .bk-candidate-menu {
        background-color: #eff2f5;
        background-repeat: no-repeat;
        background-position: right bottom;
        overflow: hidden;

        .horizontal-inner-container {
            min-height: 532px;
            padding-bottom: 80px;

            .hex-menu {
                padding-top: 60px;
                float: left;
                width: 465px;
                min-height: 50px;
                margin-right: -110px;

                .hex-container {
                    float: right;
                    margin-top: -21.8px;

                    &.first {
                        &.left-hex {
                            margin-right: 0;
                        }
                        margin-top: 0;
                    }

                    &.left-hex {
                        margin-right: 190px;
                    }

                    &.right-hex {
                        margin-right: 154px;
                    }

                    .hexagon-sm-menu {
                        position: relative;
                        width: 72px;
                        height: 41.57px;
                        margin: 20.78px 0;
                        background-color: #51667a;

                        .hex-content {
                            .candidate-icon {
                                height: 41px;
                                width: 72px;
                                background-repeat: no-repeat;
                                background-position: center;
                                background-size: auto 33px;
                                opacity: 0.7;
                            }
                        }

                        &.first {
                            background-color: $brand-color-1;

                            &:before {
                                border-bottom: 20.78px solid $brand-color-1;
                            }

                            &:after {
                                border-top: 20.78px solid $brand-color-1;
                            }
                        }

                        &:after,
                        &:before {
                            content: "";
                            position: absolute;
                            width: 0;
                            border-left: 36px solid transparent;
                            border-right: 36px solid transparent;
                        }

                        &:before {
                            bottom: 100%;
                            border-bottom: 20.78px solid #51667a;
                        }

                        &:after {
                            top: 100%;
                            width: 0;
                            border-top: 20.78px solid #51667a;
                        }
                    }
                }

                a {
                    float: right;

                    .menu-title {
                        z-index: 100;
                        cursor: pointer;
                        float: right;
                        padding: 12px 16px;
                        background-color: $brand-color-1;
                        font-family: $brand-font-2;
                        @include font(15,17,600);
                        color: #c6ccd8;

                        &.first {
                            margin-top: 21px;
                        }

                        &.active {
                            color: #fff;
                        }

                        &:hover {
                            color: #fff;
                        }
                    }
                }

                .active {
                    .hex-container {
                        .hexagon-sm-menu {
                            .hex-content {
                                .candidate-icon {
                                    opacity: 1;
                                }
                            }
                        }
                    }

                    .menu-title {
                        color: #fff;
                    }
                }
            }

            .candidate-page-content {
                overflow: hidden;
                min-height: 50px;
                padding: 140px 10px 0;

                form {
                    label {
                        @include font (13,15,100);
                        font-family: $brand-font-1;
                        color: $brand-color-1;
                    }

                    .form-group {
                        margin-bottom: 10px;
                    }

                    input {
                        &.btn {
                            float: right;
                        }
                    }
                }
            }

            &.horizontal-inner-container-candidate-profile {
                .candidate-page-content {
                    h2 {
                        font-family: $brand-font-1;
                        @include font(38,40,100);
                        color: $brand-color-1;
                        padding-left: 15px;
                    }
                }
            }
        }
    }
}
