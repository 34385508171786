html{
  height: 100%;
  box-sizing: border-box;
}

body{
  background-color:#fff;
  font-family:$brand-font-1;
  @include font(14,18,400);
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  letter-spacing:0px;
  padding-bottom: 0;
  position:relative;
  box-sizing: border-box;
  margin: 0;
  min-height: 100%;

  @include mq(tablet){
    padding-bottom: 180px;
  }
}

#wrapper{
  position:relative;
  height:100%;
  width:100%;
  box-sizing: border-box;
}

.row {
    margin-left: 0px;
    margin-right: 0px;
}

section#main{
  z-index:0;
  height:100%;
}

@include placeholder(#b9b9b9,14);


.form-control{
  border-radius: 0px;
  box-shadow: none;

}


header{
  background-color:$brand-color-1;
  color: #fff;
  .login-form-bar{
    display:none;
    @include mq(672px){
      display:block;
    }
  }
  li{
    ul{
        .login-form-dropdown{

          form{
            background-color: rgba(99, 125, 150, 0.7);
            padding:10px 5px;
            .form-title-content{
              width:100%;
              text-align:center;
            }
            .row{
              margin: 5px 0px;
            }
            .row-buttons{
              text-align:center;
              a{
                float:none !important;
                &#btn-logout{
                  background-color: $brand-color-2;
                  &:hover{
                    background-color: $brand-light-color-5;
                  }
                }
                &#btn-space{
                  background-color: $brand-color-2;
                  &:hover{
                    background-color: $brand-light-color-5;
                  }
                }
              }
            }
          }

          .btn{
            min-width: 100px;
          }

          .form-control,.form-group{
            margin-bottom: 0px !important;
          }

          .form-title-content {
            float: right;
            display: inline-block;
            padding-right:0px;
            height: 34px;
          }

          .form-title{
            @include font(18,18,700);
            margin-bottom: 5px;
          }

          a{
            @include font(12,14,400);
            color:#fff;
          }

          .col-md-3{
            padding: 0 7px;
          }

          input[type="text"],input[type="password"]{
            height:32px;
            padding:0 10px;
            width:100%;
            border:none;
          }

          input[type="submit"] {
            margin-left:15px;
          }

          .form-title-content {

          }

          .error-login-p{
            margin: 5 0;
            margin-top:5px;
            color:$brand-color-2;
          }
        }
    }
  }


  .login-form {
    padding:15px;
    .btn{
      min-width: 100px;
    }

    .form-control,.form-group{
      margin-bottom: 0px !important;
    }

    .form-title-content {
      float: right;
      display: inline-block;
      padding-right:15px;
      height: 34px;
    }

    .form-title{
      @include font(18,18,700);
      margin-bottom: 5px;
    }

    a{
      @include font(12,14,400);
      color:#fff;
    }

    .col-md-3{
      padding: 0 7px;
    }

    input[type="text"],input[type="password"]{
      height:32px;
      padding:0 10px;
      width:100%;
      border:none;
    }

    input[type="submit"] {
      margin-left:15px;
    }

    .form-title-content {

    }

    .error-login-p{
      margin: 5 0;
      margin-top:5px;
      color:$brand-color-2;
    }
  }

}

.home-header{
  padding : 0px 0px 106px;
  background-repeat: no-repeat;
  background-size: cover;

  .horizontal-inner-container{
    padding: 0 0px 10px;

    @include mq(tablet){
        padding: 40px 0px 10px;
    }
  }

  .left-header{
    text-align:center;
    float:none;
    @include mq(672px){
        float:left;
    }
    .logo{
      img{
        width: 360px;
        margin-top: 10px;
        @include mq(799px){
          width: auto;
          margin-top: 0px;
        }
      }
    }
  }

  .right-header{
    display: block;

    @include mq(672px){
      float:right;
    }
    .navbar-toggle{
      float: left;
      border:none;
      background-color: transparent !important;

      &:hover{
        background-color: transparent;
      }
      .icon-bar{
        background-color:#fff;
        width: 25px;
        height: 4px;
      }
    }
    .navbar-menu-header{
      float:left;
      text-align:left;
    }

    .navbar-login-header{
      float:right;
      @include mq(672px){
        display:none;
      }
      li{
        a{
          color:#fff;
          background-color: transparent;
          float:right;
          &:hover{
            background-color: transparent;
          }
          .fa-user{
            font-size: 30px;
          }
        }
      }
    }
  }

  .white-bar{
    background-color: rgba(255,255,255,0.8);
    nav {
      ul {
        li {
          a {
          color:$brand-color-1;
          &:hover{
            background-color:$brand-dark-color-1;
            color:#c8ccd8;
            text-decoration:none;
          }
          }
        }
      }
    }
    .right-header{
      .navbar-toggle{
        .icon-bar{
          background-color:$brand-color-1;
        }
      }
    }


  }
  h1{
    text-align:center;
    font-family:$brand-font-2;
    @include font(40,42,600);
    font-style: italic;
    margin-top:40px;
    margin-bottom:-10px;
    text-transform:uppercase;
  }

  nav {
    &.navbar-default{
      background-color: transparent;
      border-color: transparent;
    }
    .navbar-collapse{
      border:none;
      box-shadow:none;

      ul {
         padding-left:0px;
         background-color:rgba(99,125,150,0.7);
          @include mq(672px){
            background-color:transparent;
          }
        li {
          display:inline-block;
          width: 100%;
          @include mq(672px){
            width:auto;
            padding: 0 5px;
          }
          text-align:center;
          vertical-align: middle;
          height: 70px;

          a {
            color:#fff;
            @include font(16,18,400);
            @include mq(672px){
              @include font(13,18,400);
              @include mq(799px){
                @include font(16,18,400);
              }
            }

            display:table;
            height: 70px;
            width: 100%;
            @extend .animate;

            .link-content{
              display: table-cell;
              vertical-align: middle;
              height: 70px;
              white-space: nowrap;
            }

            &:hover{
              background-color:#c8ccd8;
              color:$brand-dark-color-1;
              text-decoration:none;
            }

          }

          .dropdown-content{
              position: absolute;
              a{
                height: 60px;
                padding-top:20px;
              }
            }

          &.active {
            a {
              text-decoration: underline;
            }
          }
        }
    }
    }

  }




  .navbar-toggle .icon-bar + .icon-bar {
    margin-top: 5px;
  }

  .search-form {

    padding:80px 40px 50px;

    form{
      max-width: 860px;
      margin: 0 auto;
    }

    .search-input{
      input[type="text"] {
        height:60px;
        width:100%;
        padding-left:20px;
        @include font(25,60,100);
        color:$brand-dark-color-1;
        background-color:rgba(255,255,255,0.7);
        border: none;

        &::-webkit-input-placeholder { color: #707070; font-style: italic;}  /* WebKit, Blink, Edge */
        &:-moz-placeholder { color: #707070; font-style: italic;}  /* Mozilla Firefox 4 to 18 */
        &::-moz-placeholder { color: #707070; font-style: italic;}  /* Mozilla Firefox 19+ */
        &:-ms-input-placeholder { color: #707070; font-style: italic;}  /* Internet Explorer 10-11 */
        &::-ms-input-placeholder { color: #707070; font-style: italic;}  /* Microsoft Edge */

        padding-right: 55px;
        include mq(672px){
          padding-right:230px;
        }
      }

      .submit-btn-container{

        position:absolute;
        top:0px;
        right: 0px;
        min-width: 65px;
        min-height: 60px;
        cursor:pointer;
        color: $brand-color-6;
        @include mq(672px){
            color: #fff;
        }
        i.fa{
          position:absolute;
          top: 9px;
          left: 20px;
          pointer-events: none;
          font-size:37px;

        }
      }

      input[type="submit"] {
        height: 60px;
        padding-left: 50px;
        display:none;
        @include mq(672px){
          display:block;
        }
      }
    }

    .search-options {

      padding-top:5px;
      padding-left:20px;

      input[type="checkbox"]{
        padding-top: 5px;
        padding-left: 10px;
      }

      label{
        float:left;
        padding-right: 15px;
        @include font(15,20,400);
      }
    }

  }

}

section#content{
  .alert-danger{
    margin-bottom:0px;
    text-align:center;
  }

}

footer{
    padding:40px 20px 25px;
    text-align:center;
    overflow:hidden;
    right: 0;
    bottom: 0;
    left: 0;
    background-color:$brand-dark-color-2;

    @include mq(tablet){
        position:absolute;
    }

    .horizontal-inner-container {
        padding: 0;
        @include mq(tablet){
            padding: 0 30px;
        }
    }

  .title {
    text-align:left;

    p.address {
      padding-top:30px;
      color:#fff;
      text-align: center;

      @include mq(tablet){
          text-align: left;
          @include font(14,16,400);
      }
    }
  }

  .footer-logo {
      img {
          width: 80%;
          margin: 0 auto;
          display: block;
          @include mq(tablet){
              width: auto;
          }
      }
  }

  .footer-right{
    .social-buttons{
      padding-top:10px;
      text-align: right;
      padding-bottom: 15px;

      @include mq(tablet){
          padding-top:46px;
      }

      a{
        color:$brand-light-color-4;
        display: inline-block;
        font-size:26px;
        padding-left: 10px;
      }
    }

    p {
      color:#fff;
      text-align: center;

      @include mq(tablet){
          text-align: right;
      }

      a{
        color: #fff;
      }
    }
  }

}

.hide-small {
    display: none;
    @include mq(tablet){
        display: block;
    }
}

.dropdown-content {
    display: none;
    position: relative;
    background-color: #af3e69;
    min-width: 200px;
    // left: -100px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 9999;
    //height: 200px;

    a {
        color: #fff;
        padding: 7px 16px;
        text-decoration: none;
        display: block;
    }

}

.dropdown-content{
  &#menu-dropdown-content{
    height: auto;
  }
}
