.blog-post{
	padding-top: 70px;
    .item {
		.content {
	      	padding: 10px;
			min-height:330px;
			margin-bottom: 30px;

			h3{
			  font-family: $brand-font-2;
			  @include font(40,44,400);
			  font-style: italic;
			  padding-bottom: 0px;
			  margin-bottom: 5px;
			}

			p {
			  @include font(18,23,100);
			  padding-bottom:10px;
  			  font-family: $brand-font-1;

			  &.excerpt{
			  	@include font(18,23,100);
			  	color: $brand-color-1;
			  	font-family: $brand-font-1;

			  }
			}
			.image{
				&.left-align{
				    width: 340px;
    				float: left;
				}
			}
			p.date{
			  background-color:$brand-color-1;
			  padding: 3px 7px;
			  @include font(14,16,400);
			  display:inline-block;
			  color:#fff;
			}

			p.categorie {
			  	background-color:$brand-light-color-3;
			  	padding: 3px 7px;
			  	@include font(14,16,400);
			  	display:inline-block;
	  			color:$brand-dark-color-1;
			}

		}
	}
	.categories-box{
		background-color: $brand-color-5;
		overflow:hidden;
		padding:10px;
		h4{

			@include font(25,27,400);
			font-family: $brand-font-1;
			color:$brand-dark-color-1;
			margin-right: 10px;
		}
		.related-posts{
			padding:10px;
			ul {
				list-style-type: none;
				margin-left: none;
				padding-left: 0;
			}
			li{
				@include font(18,20,100);
				margin-bottom: 20px;
				cursor:pointer;
			}

		}
		.label-box{
			background-color:$brand-light-color-3;
			padding: 3px 7px;
			@include font(14,16,400);
			display:inline-block;
			margin: 12px 5px 0px 5px;
			color:$brand-dark-color-1;

		}
		input{
			@include font(18,20,100);
			margin-top:1px;
			float:left;
			padding: 3px 5px;
			max-width:80%;
			border:none;
		}
		.search-btn{
			float:left;
			padding:0 7px;;
			background-color: white;
			margin-top:1px;
			cursor:pointer;
			.white-btn{
			color:$brand-dark-color-1;
			@include font(18,26,100);
		}
		}

	}
}
